import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { Row } from 'antd'
import SiteLayout from '../components/SiteLayout'
import SiteSearch from "../components/SiteSearch";

import Search from '../images/search.png'

const SearchPage = () => {
	return (
		<SiteLayout>
			<Row type="flex" justify="center">
				<div style={{ marginTop: 'calc( 3vh )' }} className="flex-container">

					<div className="flex-item not_found">
						<h1>Search</h1>
						<p>Start writing to find what you were looking for.</p>
						<StaticQuery
							query={graphql`
										query SearchPageQuery {
										  siteSearchIndex {
											index
										  }
										}
									  `}
							render={data => (
								<header>
									<SiteSearch searchIndex={data.siteSearchIndex.index} />
								</header>
							)}
						/>
						<p><a href="https://strands.com/company/contact/">Contact us</a> if you still need assistance. We are glad to help!</p>
					</div>

					<div className="flex-item not_found">
						<img src={Search} alt="Lorem ipsum dolor sit amet..." />
					</div>
				</div>
			</Row>
		</SiteLayout>
	)
};

export default SearchPage;
